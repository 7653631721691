// src/store/index.js
import {
	defineStore
} from 'pinia';
import configs from "@/common/config.js"

import axios from 'axios';
// import { FALSE } from 'sass';

export const useMainStore = defineStore('main', {
	state: () => ({
		groups: null,
		lists: [],
		countrys: localStorage.getItem('CONTRY') || 'in',
		heartbeatTime: null,
		returnedFromNewPage: false,
		heartCode: '', //心跳关键code
		paseUrl: process.env.VUE_APP_BASE_URL, // maboshop.com
		loadingEnd: false,
		wondowsWiths: 0,
		showGad: false,
		isVideoApp: false

	}),


	actions: {
		changeApp(value) {
			console.log(value)
			this.isVideoApp = value;
		},
		// https://open.feishu.cn/open-apis/im/v1/messages?receive_id_type=chat_id
		// 统计心跳
		flayTest(val) {
			return
			console.log('我是真的0', val)
			// clearInterval(this.heartbeatTime)
			// return
			const type = {
				receive_id: "6g7g2ge7",
				msg_type: "text",
				content: JSON.stringify(val),
			}
			const response = axios.post(
				'https://open.feishu.cn/open-apis/im/v1/messages?receive_id_type=user_id', {
					receive_id: "6g7g2ge7",
					msg_type: "text",
					content: val,
				}, {
					headers: {
						"Authorization": "Bearer t-g1048ea0ORLGNFLD7U5CORBPW5WKR7V2JYM6TWIS",
						"content-type": "applition/json"
					}
				});
			if (response) {
				console.log('我是真的1', response)
				response.then(res => {
					console.log('我是真的000', res)
				})
			} else {
				console.log('我是真的2', response)
			}
			return



			try {


				const response1 = axios.post(
					'https://open.feishu.cn/open-apis/auth/v3/tenant_access_token/internal', {
						app_id: "cli_a63f40bbbfb9100e",
						app_secret: "wvBFrSjIqQwglDBEoqsHfbUQArdmNIj2"
					}, {
						headers: {
							"content-type": "applition/json"
						}
					});
				console.log('我是凭证1', response1)
				if (response1) {
					console.log('我是凭证1', response1)
					response1.then(res => {
						console.log('我是凭证2', res)
						if (res && false) {
							const response = axios.post(
								'https://open.feishu.cn/open-apis/im/v1/messages?receive_id_type=chat_id', {
									visitNo: val,
								}, {
									headers: {
										"Bearer": "t-g1048dlqAI2TIL6VDSZGID4EDHFWIBQPR6CMMBK2",
										"content-type": "applition/json"
									}
								});
							if (response) {
								console.log('我是真的1', response)
								response.then(res => {
									console.log('我是真的000', res)
								})
							} else {
								console.log('我是真的2', response)
							}
						}
					})
				} else {
					console.log('我是凭证3', response)
				}

			} catch (error) {
				console.error('Error in statisticsClick1:我是真的3', error);
				// 可以在这里处理函数内部的其他错误
			}

		},

		async fetchData1(val) {
			console.log('环境判断', process.env.NODE_ENV)
			this.wondowsWiths = val
			console.log('我的url', this.$Axios)
			// this.countrys = localStorage.getItem('CONTRY')
			try {
				const country = this.countrys
				const response = await axios.post(this.paseUrl + '/visitors/v1/info', {
					// const response = await axios.post('http://192.168.1.14:9001/visitors/v1/info', {
					country,
				}, {
					headers: {
						'X-Country': this.countrys || 'in'
						// 你可以添加更多的header
					}
				});
				console.log('ceshi', response)
				const myGroup = response.data.data
				this.groups = response.data.data;
				const params = {
					user_statistics: myGroup.group,
				}
				const params2 = {
					group: myGroup.group,
					country: this.countrys
				}
				if (window.gtag) {
					window.gtag('set', 'user_properties', params2);
				} else {
					gtag('set', 'user_properties', params2);
				}
				if (this.groups.newer) {
					const responseList = await axios.post(this.paseUrl + '/videos/v2/recommend/newer', {
						country: this.countrys,
						group: myGroup.group
					});
					this.lists = responseList.data.data
					this.loadingEnd = true
				} else {
					// 右图7
					this.lists = []
					this.loadingEnd = true
				}
			} catch (error) {
				console.error("请求出错：", error);
			}
		},

		// 统计接口调用
		statisticsClick(page, val) {
			try {
				var that = this;
				const response = axios.post(this.paseUrl + '/statistics/visitors/v1/launch', {
					country: this.countrys,
					page: page,
					videoId: val || ''
				}, {
					headers: {
						'X-Country': this.countrys || 'in'
						// 你可以添加更多的header
					}
				});

				response.then(res => {
					if (res) {
						var heart = res.data.data.visitNo
						console.log('ids', heart);
						this.heartCode = heart;
						if (process.env.NODE_ENV == 'production') {
							this.heartbeat(heart);
						}

					}
				}).catch(error => {
					console.error('Error in axios response:', error);
					// 可以在这里处理响应错误，例如显示错误消息
				});
			} catch (error) {
				console.error('Error in statisticsClick:', error);
				// 可以在这里处理函数内部的其他错误
			}

		},
		// 统计心跳
		heartbeat(val) {
			// console.log('开始心跳了', val)
			// clearInterval(this.heartbeatTime)
			// return


			try {
				this.heartbeatTime = setInterval(() => {
					// console.log('开始心跳了', val)
					// const response = axios.post('https://api.maboshop.com/visitors/v1/heart', {
					const response = axios.post(this.paseUrl +
						'/statistics/visitors/v1/heart', {
							visitNo: val,
						}, {
							headers: {
								'X-Country': this.countrys || 'in'
								// 你可以添加更多的header
							}
						});
					if (response) {
						// console.log('开始心跳了成功了')
					}
				}, 10000)
			} catch (error) {
				console.error('Error in statisticsClick1:', error);
				// 可以在这里处理函数内部的其他错误
			}

		},
		// 停止心跳
		heartStop() {
			console.log('停止心跳了')
			clearInterval(this.heartbeatTime)
		},
		setReturnedFromNewPage(value) {
			this.returnedFromNewPage = value;
		}
	},
});