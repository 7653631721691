<template>
	<!-- <KeepAlive>
		<RouterView />
	</KeepAlive> -->
	<!-- <div id="topon-placement-n6673fa177a269"></div> -->

	<router-view v-slot="{ Component }">
		<keep-alive>
			<component :is="Component" v-if="$route.meta.keepAlive" />
		</keep-alive>
		<component :is="Component" v-if="!$route.meta.keepAlive" />
	</router-view>
</template>

<script>
	// import TOPON_H5_SDK from '@/assets/topOn.js'
	import {
		RouterView
	} from 'vue-router'

	import {
		useMainStore
	} from '@/store';
	import {
		nextTick
	} from 'vue';
	import config from '@/assets/config.json';

	const configure = config.configure;

	export default {
		name: 'App',
		data() {
			return {
				configure: configure,
				errorsList: [],
			}
		},
		created() {
			// flter
			// 区分国家
			// console.log('测试', )
			const locations = window.location.href
			// const locations = 'https://my.maboshop.com'
			// const counList = ['in', 'ph', 'vn', 'th', 'my']
			const inFilter = locations.split('.')
			console.log(locations)
			if (locations && locations.indexOf('?ct=') != -1) {
				const inFilter2 = locations.split('?ct=')
				const contrys = inFilter2[1].slice(0, 2);
				localStorage.setItem('CONTRY', contrys)
				console.log('woshiguojiadaimingci', contrys)
			} else if (inFilter && inFilter[0]) {
				const myIn = inFilter[0].split('https://')
				// console.log('测试', myIn)
				if (myIn[1] && myIn[1].length <= 3) {
					// console.log('测试2', myIn)
					localStorage.setItem('CONTRY', myIn[1] == 'www' ? 'in' : myIn[1])
				} else {
					// console.log('测试3', myIn)
					localStorage.setItem('CONTRY', 'in')
				}
			} else {
				localStorage.setItem('CONTRY', 'in')
			}
			const store = useMainStore();
			store.fetchData1()
		},
		mounted() {
			// console.log('广告ids', this.configure['placementOptions' + '1'])
			this.$nextTick(() => {
				// this.initial();
			});
		},
		methods: {
			initial() {
				try {
					console.log('初始化SDK...');
					window.TOPON_H5_SDK.init(this.configure.initOptions);
					// this.initPlacement();
				} catch (error) {
					console.error('SDK 初始化错误', error, 'error')
					// console.log('SDK 初始化错误', error, 'error')
					// this.trackLogger('SDK 初始化错误', error, 'error');
				}
			},
			initPlacement() {
				try {
					console.log('初始化广告位...');
					const placementInstance = window.TOPON_H5_SDK.initPlacement(this.configure.placementOptions[this
						.keys]);
					placementInstance.onLoad = this.adLoad;
					placementInstance.onError = this.adError;
					placementInstance.onShow = this.adShow;
					placementInstance.onClick = this.adClick;
					placementInstance.onClose = this.adClose;
					this.$placementInstance = placementInstance; // 使用$前缀来标识
					// setTimeout(() => {
					// 	this.showAd()
					// }, 500)
				} catch (error) {
					console.error('SDK 初始化错误', error, 'error')
					// this.trackLogger('广告位初始化错误', error, 'error');
				}
			},
		}

	}
</script>

<style lang="scss">
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: PingFangSC, PingFang SC;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		// margin-top: 60px;
	}

	body {
		margin: 0;
	}

	// 主题色

	.adsbygoogle {
		// width: 100%;
		background-color: #fff;
		/* 深色模式背景 */
		/* 深色模式文字 */
		// border-radius: 16px;
	}

	.borderRoud {
		overflow: hidden;
		margin: 16px 0;
		border-radius: 15px;
		padding: 10px;
	}

	.theme {
		background-color: #fff;
		/* 默认浅色背景 */
		color: #000000;

	}

	.scroll {
		background-color: #f2f2f2;
	}

	.theme-ads {

		// border-radius: 16px;
		// min-height: 100px;
		background-color: #fff;

	}

	.theme-adsShow {
		padding: 10px;
		margin-top: 16px;
		overflow: hidden;
	}


	.theme-det-fucolor {
		color: #606060;
	}

	.changes {
		filter: invert(0%) brightness(100%);
	}

	.my_click {
		background-color: #f2f2f2;
	}

	// .my_zan .changesdetail {
	// 	filter: invert(0) brightness(80%) !important;
	// }

	// .my_cai .changesdetail {
	// 	filter: invert(100%) brightness(80%) !important;
	// }


	@media (prefers-color-scheme: dark) {

		// ===================================
		body {
			background-color: #000;
		}

		.scroll {
			background-color: #000;
		}

		.loadingColor {
			background-color: #000;
		}

		.loadingDiot {
			background-color: #f2f2f2 !important;
		}

		.lodingsTexxt {
			color: #f2f2f2 !important;
		}




		.theme-home-eye {
			// filter: invert(0%) brightness(100%);
			filter: invert(100%) brightness(100%);
		}





		.theme-border {
			border-bottom: 1px solid #484848 !important;
		}

		// .theme-types .darkTt {
		// 	filter: invert(100%) brightness(100%);
		// }

		.theme-page {
			background-color: #000 !important;

		}

		.theme-page .darkTa {
			background-color: #2c2c2c;
		}

		.theme-ads .darkTa {
			background-color: #2c2c2c;
			color: #f1f1f1 !important;
		}


		// .theme-det-fucolor {
		// 	color: #aaaaaa;
		// }
		.theme-det-fucolor .darkTdf {
			color: #aaaaaa;
		}



		// ==========================================

		.theme {
			background-color: #2c2c2c;
			// background-color: #fff;
			/* 默认浅色背景 */
			color: #f1f1f1 !important;

			.loadingText {
				color: #eee !important;
			}

			.spinner-container {
				color: #fff;
			}


		}

		.theme .darkTs {
			// background-color: #f1f1f1;
			// /* 默认浅色背景 */
			// color: #000000;
			background-color: #000 !important;
			/* 默认浅色背景 */
			color: #f1f1f1 !important;

		}

		.adsbygoogle {
			background-color: #2c2c2c !important;
			/* 深色模式背景 */
			color: #f1f1f1 !important;
			/* 深色模式文字 */
		}

		.changes {
			filter: invert(100%) brightness(80%);
		}

		.like-btn {
			// width: 19px;
			// height: 22px;
			// background-image: url('@/assets/img/zanhei.png');
			// background-size: cover;
			// background-color: #F2F2F2;
		}

		// .my_zan .changesdetail {
		// 	filter: invert(0) brightness(80%);
		// }

		// .my_cai .changesdetail {
		// 	filter: invert(100%) brightness(80%);
		// }
		.changesdetailces {
			filter: invert(0) brightness(80%) !important;
		}
	}


	@media (prefers-color-scheme: light) {
		.changesdetailces {
			filter: invert(0) brightness(80%) !important;
		}

		.my_zan .changesdetail {
			filter: invert(0) brightness(80%) !important;
		}

		.my_cai .changesdetail {
			filter: invert(100%) brightness(80%) !important;
		}
	}
</style>